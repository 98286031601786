<template>
<div style="padding-bottom: 30px; ">
    <!-- <v-row style="margin-top: 0px" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>

    <v-row class="align-center d-flex h-0">
        <v-col lg="4" sm="9" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="">
                <v-card-title class="text-primary" style="
              justify-content: center;
              font-weight: 600 !important;
              padding-bottom: 20px;
              font-size: 24px;
            ">
                    <span>{{form_title}}</span>
                </v-card-title>

                <v-card-subtitle style="
              height: 23px;
              text-align: center !important;
              background-color: #ac55991f !important;
              color: #a84192;
            ">
                    <span>
                        <li>{{form_sub_title}}</li>
                    </span>
                </v-card-subtitle>

                <v-card-text style="border-radius: 7px; padding-top: 25px">
   

                    <!-- user_patient -->
                    <v-row v-if="user_type == 'user_patient'" dense align="center">
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                            <v-select class="custom-field" v-model="prefix" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                            <v-text-field v-model="first_name" :rules="nameRules" label="ชื่อ" dense outlined required></v-text-field>
                            <v-text-field v-model="last_name" :rules="nameRules" label="นามสกุล" dense outlined required></v-text-field>

                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="birthdate" :rules="dateRules" label="วัน/เดือน/ปีเกิด" append-icon="calendar_month" dense outlined required  readonly v-on:click:append="menu=true" v-bind="attrs" v-on="on"></v-text-field>

                                </template>
                                <v-date-picker v-model="birthdate" locale="th-th" :active-picker.sync="activePicker" no-title :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" min="1920-01-01" @change="save"></v-date-picker>
                            </v-menu>
                            <!-- <v-text-field v-model="idcard" :rules="nameRules" counter="13" type="number" label="เลขบัตรประชาชน" dense outlined required></v-text-field> -->
                            <v-row class="margin-bottom: -21px;">
                                <v-col>
                                    <v-text-field v-model="weight" type="number" :rules="wRules" label="น้ำหนัก (kg)" dense outlined></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="height" type="number" :rules="wRules" label="ส่วนสูง (cm)" dense outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <span>โรคประจำตัว</span>
                            <v-row>
                                <v-col>
                                    <v-checkbox v-model="highpressure" label="ความดันสูง" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="hyperlipidemia" label="ไขมันสูง" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="alzheimer" label="อัลไซเมอร์" color="primary" dense hide-details></v-checkbox>

                                </v-col>
                                <v-col>
                                    <v-checkbox v-model="diabetes" label="เบาหวาน" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="arrhythmai" label="หัวใจเต้นผิดจังหวะ" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="depress" label="ซึมเศร้า" color="primary" dense hide-details></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-checkbox v-model="heart" label="โรคหัวใจหรือหลอดเลือดสมอง" color="primary" dense hide-details style="padding-bottom: 21px;"></v-checkbox>

                            <v-text-field counter="5" v-model="postcode" type="number" :rules="nameRules" label="รหัสไปรษณีย์" dense outlined required></v-text-field>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field v-model="province" label="จังหวัด" dense outlined disabled></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="selected_district" :item-value="district_list" :items="district_list" :rules="[(v) => !!v || 'โปรดเลือก']" label="อำเภอ/เขต" dense outlined required></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="location_id" :item-value="'location_id'" :item-text="'subdistrict'" :items="filterSubdistrict" :rules="[(v) => !!v || 'โปรดเลือก']" label="ตำบล/แขวง" dense outlined required></v-select>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="address" label="ที่อยู่" dense outlined></v-text-field>
                            <v-text-field v-model="phone_number" :rules="phoneRules" label="เบอร์โทรศัพท์" dense outlined required></v-text-field>
                            <!-- {{selected_district}} -->
                            <!-- {{selected_district_list}} -->
                            <!-- {{filterSubdistrict}} -->
                            <!-- {{location_id}} -->
                            <br>
                            <br>

                        </v-form>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
        <v-btn @click="$router.go(-1)" style="border-color: #d6d6d629" outlined color="purple" class="border-radius-xl">
            <v-icon small>west</v-icon>
        </v-btn>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
            ยืนยันข้อมูล
        </v-btn>
    </v-row>
    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-center" justify="center">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>
    </v-footer>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "register-select-type",
    components: {
        WaitingModal,
    },
    data() {
        return {
            new_manual_patient_id:'',
            register_form:{},
            filltered_subdistrict_list: [],
            selected_district: '',
            selected_district_list: [],
            provider_list: [],
            provider_list_id: '',
            dialog: false,
            active_waiting: false,
            title: '',
            status: '',
            postcode: '',
            form_title: '',
            form_sub_title: '',
            activePicker: null,
            date: null,
            menu: false,
            user_type: "",
            pdpa_accept: false,
            valid: true,
            name: "",
            prefix: '',
            first_name: '',
            last_name: '',
            birthdate: '',
            phone_number: '',
            idcard: '7777',
            mdnumber: '',
            reg_token: '',
            passcode: '',
            lineid: '',
            weight: '',
            height: '',
            highpressure: 0,
            hyperlipidemia: 0,
            alzheimer: 0,
            heart: 0,
            diabetes: 0,
            arrhythmai: 0,
            depress: 0,
            province: '',
            district: '',
            subdistrict: '',
            subdistrict_list: [],
            location_id: '',
            location_list: '',
            district_list: [],
            address: '',
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            phoneRules: [
                v => (v && v.length > 8 && v.length < 11) || "โปรดกรอกเลขหมายที่ถูกต้อง"
            ],
            wRules: [
                v => (v && v > 30 && v < 220) || "โปรดกรอกจำนวนที่ถูกต้อง"
            ],
            dateRules: [v => !!v || "โปรดเลือกวันที่", ],

        };
    },

    mounted() {
        this.user_type = 'user_patient'
        this.getFromTitle()
    },

    watch: {
        provider_list_id(val) {
            const seleted_provider = this.provider_list.filter(item => item.id == val)[0]
            //console.log(seleted_provider)
            if (seleted_provider.postcode) {
                this.postcode = seleted_provider.postcode
            }
            this.subdistrict = seleted_provider.subdistrict
            this.address = seleted_provider.address
            //console.log('88888')
            //console.log(seleted_provider)
            // this.subdistrict = seleted_provider.subdistrict
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        async postcode(val) {
            if (val && val.length == 5) {
                this.active_waiting = true
                this.title = 'กำลังค้นหา',
                    this.status = 'loading'
                await Vue.axios.post(APIURL + '/get_location', {
                    "postcode": val
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data) {
                        this.active_waiting = false
                        //console.log(response.data)
                        this.province = response.data.province
                        this.district = response.data.district
                        this.subdistrict_list = response.data.subdistrict_list
                        this.location_id = response.data.location_id
                        this.location_list = response.data.location_list
                        //console.log('*******************')
                        //console.log(this.location_id)
                        this.getDistrictKeys()
                    } else {
                        this.active_waiting = false
                        this.province = 'ไม่พบข้อมูล'
                        this.district = 'ไม่พบข้อมูล'
                    }

                })
            }

        }
    },
    computed: {
        filterSubdistrict: function () {
            // //console.log(this.postcode)
            if (!this.postcode || this.postcode.length < 5 || !this.filterSubdistrictByDistrict(this.selected_district)) {
                return []
            }
            return this.filterSubdistrictByDistrict(this.selected_district)
        },
    },
    methods: {
        checkProviderList() {
            if (this.provider_list && this.provider_list.length == 0) {
                this.getProviderList()
            }
        },
        async getProviderList() {
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_provider_list', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.provider_list = response.data.provider_list;
                    //console.log('777777777777777')
                    //console.log(this.provider_list)

                } else {
                    this.active_waiting = false
                    //console.log(response)
                }

            })

        },
        getDistrictKeys() {
            const temp_list = this.location_list.reduce((prev, cur) => ({
                ...prev,
                [cur.district]: (prev[cur.district] || []).concat(cur)
            }), {})
            const keys = Object.keys(temp_list);
            // print all keys
            //console.log(keys);
            if(keys){
                this.district_list = keys
            }
            else{
                this.district_list = []
            }
            
        },

        filterSubdistrictByDistrict: function (subdistrict_list) {
            //console.log(subdistrict_list)
            return this.selected_district ?
                this.location_list.filter((location_list) => !location_list.district.indexOf(this.selected_district)) :
                this.location_list;

        },
        update_status_wait(val) {
            this.active_waiting = val
        },

        getFromTitle() {
            if (this.user_type === 'user_provider') {
                this.form_title = 'ลงทะเบียนโรงพยาบาล/คลินิก'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.user_type === 'user_osm') {
                this.form_title = 'ลงทะเบียน อสม.'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.user_type === 'user_patient') {
                this.form_title = 'ลงทะเบียนผู้ใช้งาน/ผู้ป่วย'
                this.form_sub_title = 'Caregiver/Patient'
            }
        },
        save(date) {
            this.$refs.menu.save(date)
        },
        validate() {
            const res = this.$refs.form.validate();
            //console.log(res)
            if (res) {
                this.submitRegister()
            }
            // 
        },
        async submitRegister() {
            // send submit_form to the server
            // await        
            this.register_form = {
                "user_type": 'user_patient',
                "user_id": '',
                "location_id": this.location_id,
                "address": this.address,
                "pdpa_accept": 1,
                "gender": "m",
                "birthdate": this.birthdate,
                "prefix": this.prefix,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "phone_number": this.phone_number,
                "idcard": this.idcard,
                "lineid": (Math.random().toString(32).slice(2)) + (Math.random().toString(32).slice(2)),
                "weight": this.weight,
                "height": this.height,
                "highpressure": this.highpressure,
                "hyperlipidemia": this.hyperlipidemia,
                "alzheimer": this.alzheimer,
                "heart": this.heart,
                "diabetes": this.diabetes,
                "arrhythmai": this.arrhythmai,
                "depress": this.depress,
                "mdnumber": this.mdnumber,
                "provider_list_id": this.provider_list_id,
                "reg_token": this.reg_token,
                "passcode": this.passcode,
                "postcode": this.postcode
            }

            if (this.user_type == 'user_patient') {
                //console.log(this.register_form)
                //call api patient
                this.active_waiting = true
                this.title = 'กำลังลงทะเบียน',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/register_user_patient', this.register_form, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.res = response.data
                    //console.log(response.data)

                    if (this.res.detail.user_id) {
                        this.title = 'เพิ่มข้อมูลสำเร็จ';
                        this.status = 'done'

                        // this.$store.commit('setLoginDetail', {
                        //     user_id: this.res.detail.user_id,
                        //     user_type: this.res.detail.user_type,
                        //     user_token: this.res.detail.user_token,
                        // });
                        this.new_manual_patient_id = this.res.detail.user_id
                        setTimeout(() => {
                            this.active_waiting = false
                            // this.$router.push('/home_checkmong')
                            this.submitVal()
                            // this.$router.go(-2)
                        }, 1500)
                    } else {

                        if (this.res.detail == 'lineid_already_registered') {
                            this.title = 'บัญชีไลน์นี้ลงทะเบียนไปแล้ว';
                            this.status = 'fail'
                            // this.$store.commit('setLoginDetail', {
                            //     user_id: this.res.detail.user_id,
                            //     user_type: this.res.detail.user_type,
                            //     user_token: this.res.detail.user_token,
                            // });

                            setTimeout(() => {
                                localStorage.clear();
                                this.$router.go(-2)
                            }, 1500)

                        }
                        if (this.res.detail == 'idcard_is_not_valid') {
                            this.title = 'เลขบัตรประชาชนไม่ถูกต้อง';
                            this.status = 'fail'
                        }
                        if (this.res.detail == 'idcard_already_registered') {
                            this.title = 'เลขบัตรประชาชนนี้ลงทะเบียนไปแล้ว';
                            this.status = 'fail'
                        }
                        if (this.res.detail == 'cannot_get_last_user_id' || this.res.detail == 'cannot_create_user') {
                            this.title = 'ขออภัย ระบบขัดข้อง';
                            this.status = 'fail'
                        }

                    }
                })
            }
           

        },
        async submitVal() {

            // this.active_waiting = true
            // this.title = 'กำลังเพิ่มผู้ป่วยในการดูแล',
            // this.status = 'loading'


            await Vue.axios.post(APIURL + '/add_user_relation', {
                "user_id_provider_osm": this.$store.state.login_detail.user_id,
                "patient_id": this.new_manual_patient_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail == 'user_relation_added') {
                    // this.title = 'เพิ่มข้อมูลสำเร็จ';
                    // this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.$router.go(-2)
                    }, 0)
                } 
                else if(response.data.detail == 'cannot_add_user_relation'){
                    // this.title = 'ผู้ป่วยอยู่ในรายชื่ออยู่แล้ว';
                    // this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.$router.go(-2)
                    }, 0)
                }
                
                else {
                    this.active_waiting = false
                    this.title = 'โปรดลองใหม่อีกครั้ง',
                        this.status = 'fail'
                }

            })

        },
    
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        goHomeCheckmong() {

            this.$router.push('/home_checkmong')

        },
        goBack() {
            this.router.go(-1)
        }
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #FFFFFF;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #A84192;
}

.v-main__wrap {

    padding-bottom: 90px;
}

.v-select .vs__selected-options {
    flex-wrap: nowrap;
}
</style>
